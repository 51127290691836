import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { DialogCloseIcon } from "../../../icons/user.icon";

export type TopupInvestmentFormType = {
  amount?: number;
  currency?: string;
};

export const SelectWithdrawalMethodDialog = (props: {
  isOpen: boolean;
  closeModal: (method?: 'paypal'|'cashapp'|'direct'|'crypto') => void;
}) => {


  return (
    <>
      <Transition.Root show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="modal fixed inset-0 z-50 h-full overflow-y-auto flex items-center justify-center"
          onClose={() => props.closeModal()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* <!-- My Content --> */}
              <div
                className="align-middle rounded-lg bg-white dark:bg-darkblack-600 p-6 pb-10 transition-all relative inline-block "
                style={{ textAlign: "left", maxWidth: "calc(100vw - 10%)" }}
              >
                <div>
                  <header>
                                    <div>
                                      <h3 className="font-bold text-bgray-900 dark:text-white text-2xl mb-1">
                                      Withdrawal Method
                                      </h3>
                                    </div>
                                    <div className="absolute top-0 right-0 pt-5 pr-5">
                                      <button
                                        type="button"
                                        id="step-1-cancel"
                                        onClick={() => props.closeModal()}
                                        className="rounded-md bg-white dark:bg-darkblack-500 focus:outline-none"
                                      >
                                        <span className="sr-only">Close</span>
                                        <DialogCloseIcon />
                                      </button>
                                    </div>
                                  </header>
                    <div
                      className="grid grid-cols-1 gap-5 pb-14 sm:grid-cols-2 2xl:grid-cols-3 2xl:gap-10"
                    >
                      <div
                        className="relative rounded-lg bg-gray-100 p-6 dark:bg-darkblack-500"
                        onClick={() => props.closeModal('crypto')}
                      >
                        <div className="mb-5 flex gap-x-2">
                          <img width={30} height={30} src="/images/payments/bitcoin_logo_mini.png" alt="" />
                          <img width={30} height={30} src="/images/payments/ethereum_logo_mini.png" alt="" />
                          <img width={30} height={30} src="/images/payments/tether_logo_mini.png" alt="" />
                        </div>
                        <h4
                          className="mb-2 text-base font-bold text-bgray-900 dark:text-white"
                        >
                          Cryptocurrency
                        </h4>
                        <p className="text-sm dark:text-bgray-50">
                          Faster Withdrawals, Specify network and wallet address.
                        </p>
                        
                      </div>
                      <div
                      onClick={() => props.closeModal('paypal')}
                        className="relative rounded-lg bg-gray-100 p-6 dark:bg-darkblack-500"
                      >
                        <div className="mb-5">
                          <img src="/images/payments/paypal.svg" alt="" />
                        </div>
                        <h4
                          className="mb-2 text-base font-bold text-bgray-900 dark:text-white"
                        >
                          PayPal
                        </h4>
                        <p className="text-sm dark:text-bgray-50">
                          Friends and Family.
                        </p>
                      </div>
                      <div
                      onClick={() => props.closeModal('cashapp')}
                        className="relative rounded-lg bg-gray-100 p-6 dark:bg-darkblack-500"
                      >
                        <div className="mb-5">
                          <img  src="/images/payments/cashapp_logo_mini.png" alt="" width={128}/>
                        </div>
                        <h4
                          className="mb-2 text-base font-bold text-bgray-900 dark:text-white"
                        >
                          Cashapp
                        </h4>
                      </div>
                      <div
                      onClick={() => props.closeModal('direct')}
                        className="relative rounded-lg bg-gray-100 p-6 dark:bg-darkblack-500"
                      >
                        <div className="mb-5">
                        </div>
                        <h4
                          className="mb-2 text-base font-bold text-bgray-900 dark:text-white"
                        >
                          Direct Bank Transfer
                        </h4>
                        <p className="text-sm dark:text-bgray-50">
                        </p>
                        
                      </div>
                    </div>
                  </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
